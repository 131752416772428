import { toast } from 'react-toastify'
import { BetaAPIMutation, BetaObject } from '../interfaces/APIModels'
import { AccountInfo } from '@azure/msal-browser'
import { SelectedFilters } from '../interfaces/ComponentModels'
import {
	DDIStatuses,
	OrderFilterKeyMappings,
	OrderStatuses,
	OrderTypes,
	ServiceTypes,
	TNFilterKeyMappings,
} from '../enums/enums'

export async function toAlphaString(betaText: string) {
	const alpha = process.env.REACT_APP_AES_ALPHA + ''
	const beta = process.env.REACT_APP_AES_BETA + ''
	const alphaMap = new Map<string, string>()

	for (let i = 0; i < beta.length; i++) {
		alphaMap.set(beta[i], alpha[i])
	}
	const alphaTextArray = Array.from(betaText, (betaChar) =>
		alphaMap.has(betaChar) ? alphaMap.get(betaChar)! : betaChar
	)
	const alphaText = alphaTextArray.join('')
	return alphaText
}

export function getEmptyAPIMutationObject() {
	var emptyBetaObj: BetaObject = {
		Content: '',
	}

	var emptyApiMutationObj: BetaAPIMutation = {
		BetaObject: emptyBetaObj,
		QueryParam: '',
	}

	return emptyApiMutationObj
}

export function getFormattedDate(): string {
	var today = new Date()
	var _year = today.getUTCFullYear() + ''
	var _month = today.getUTCMonth() + 1 + ''
	var _day = today.getUTCDate() + ''
	var _hours = today.getUTCHours() + ''
	var _minutes = today.getUTCMinutes() + ''
	var _seconds = today.getUTCSeconds() + ''

	if (_month.length < 2) {
		_month = '0' + _month
	}
	if (_day.length < 2) {
		_day = '0' + _day
	}
	if (_hours.length < 2) {
		_hours = '0' + _hours
	}
	if (_minutes.length < 2) {
		_minutes = '0' + _minutes
	}
	if (_seconds.length < 2) {
		_seconds = '0' + _seconds
	}

	var formattedDate = `${_year}-${_month}-${_day}T${_hours}:${_minutes}:${_seconds}`
	return formattedDate
}

export async function toBeta(obj: any) {
	var alphaText = JSON.stringify(obj)
	return toBetaString(alphaText)
}

export function toBetaString(alphaText: string) {
	const alpha = process.env.REACT_APP_AES_ALPHA + ''
	const beta = process.env.REACT_APP_AES_BETA + ''
	const betaMap = new Map<string, string>()

	for (let i = 0; i < alpha.length; i++) {
		betaMap.set(alpha[i], beta[i])
	}

	let betaText = ''
	for (let i = 0; i < alphaText.length; i++) {
		const alphaChar = alphaText[i]
		betaText += betaMap.has(alphaChar) ? betaMap.get(alphaChar)! : alphaChar
	}
	return betaText
}

export function numberToChar(num: number) {
	// 👇️ for Uppercase letters, replace `a` with `A`
	const code = 'A'.charCodeAt(0)
	return String.fromCharCode(code + num)
}

export function getUTCDateTimeNow() {
	var today = new Date()
	var utcToday = new Date(
		Date.UTC(
			today.getFullYear(),
			today.getMonth(),
			today.getDate(),
			today.getUTCHours(),
			today.getUTCMinutes(),
			today.getUTCSeconds()
		)
	)
	return utcToday
}

export function hasGraphScopes(scopes: string[]) {
	var scopeFound = false
	for (var i = 0; i < scopes.length; i++) {
		if (scopes[i].toLowerCase().includes('.default')) {
			scopeFound = true
		}
	}
	return scopeFound
}

export function emailValidation(email: string): boolean {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return regex.test(email)
}

export function emailContainsSpecialChars(email: string): boolean {
	// Regular expression for email validation excluding @ and .
	var specialChars = /[`!#$%^&*()+=\[\]{};:"\\|,<>\/?~]/
	return specialChars.test(email)
}

export function jsonValidator(jsonString: string) {
	try {
		JSON.parse(jsonString)
		return true
	} catch (e) {
		return false
	}
}

export function timeout(delay: number) {
	return new Promise((res) => setTimeout(res, delay))
}

// Used to get the property of an object based on the string we pass in
export function getProperty<T>(obj: T, key: keyof T) {
	return obj[key]
}

// Generic function to extract a specific property based on a dynamic property
export function extractPropertyByID<T, K extends keyof T, I>(
	array: T[],
	id: I,
	propertyToCompare: K,
	propertyName: K
): T[K] | undefined {
	const foundItem = array.find((item) => item[propertyToCompare] === id)
	return foundItem ? foundItem[propertyName] : undefined
}

// Display avatar icon
export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: 'primary.main',
			color: 'primary.contrastText',
			fontSize: '20px',
			width: '50px',
			height: '50px',
		},
		children: name[0],
	}
}

// Lead helpers
export function isAlphanumeric(value: string): boolean {
	return !!(value && /^[a-zA-Z0-9-]+$/.test(value))
}

export function tenantIDHaveCorrectFormat(value: string): boolean {
	return (value.match(/-/g) || []).length === 4
}

export function IsPhoneNumber(value: string): boolean {
	const phoneRegex = /^\+?\d+$/
	return phoneRegex.test(value)
}

export function isPhoneNumberValidLength(phoneNumber: string): boolean {
	const minLength = 7
	const maxLength = 15
	// Remove '+' sign before checking length
	const sanitizedPhoneNumber = phoneNumber.replace(/\s|\+/g, '')

	return (
		sanitizedPhoneNumber.length >= minLength &&
		sanitizedPhoneNumber.length <= maxLength
	)
}

export function isTenantIDSegmentsValid(value: string): boolean {
	const requiredSegmentLengths = [8, 4, 4, 4, 12]
	const segments = value.split('-')
	if (segments.length !== requiredSegmentLengths.length) {
		return false
	}
	for (let i = 0; i < segments.length; i++) {
		if (segments[i].length !== requiredSegmentLengths[i]) {
			return false
		}
	}
	return true
}

// Toasts
export function showErrorToast(apiErrorMessage: string) {
	toast.error(apiErrorMessage, {
		position: 'top-center',
		autoClose: 2500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}

export function showSuccessToast(apiErrorMessage: string) {
	toast.success(apiErrorMessage, {
		position: 'top-center',
		autoClose: 1200,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}

export function validateDecimalNumber(field: string): boolean {
	const regex = /^[0-9]*\.?[0-9]*$/
	return regex.test(field)
}

export const validateField = (
	field: string,
	value: string | number | null
): string => {
	switch (field) {
		case 'companyName':
			return (value as string).trim() === ''
				? 'Company Name is required'
				: containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'externalSalesAgent':
			return (value as string).trim() === '' ||
				!emailValidation(value as string) ||
				(value as string).trim().length < 5
				? 'Email is invalid'
				: emailContainsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'tenantID':
			return (value as string).trim() === ''
				? 'Tenant ID is required'
				: !isAlphanumeric(value as string) ||
				  !tenantIDHaveCorrectFormat(value as string) ||
				  (value as string).trim().length !== 36 ||
				  !isTenantIDSegmentsValid(value as string)
				? 'Invalid Tenant ID format. Example: e.g., xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
				: containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'locations':
			return (value as string).trim() === ''
				? 'Locations is required'
				: containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'customerName':
			return (value as string).trim() === ''
				? 'Customer Name is required'
				: containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'customerPhoneNumber':
			return (value as string).trim() === ''
				? 'Phone Number is required'
				: !IsPhoneNumber(value as string)
				? 'Invalid Phone Number format. Please enter a valid phone number.'
				: !isPhoneNumberValidLength(value as string)
				? 'Invalid Phone Number length. Please enter a valid phone number.'
				: ''
		case 'customerEmail':
			return (value as string).trim() === '' ||
				!emailValidation(value as string)
				? 'Email is invalid'
				: emailContainsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'addressName':
			return containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'houseNumber':
			return containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'addressLine1':
			return (value as string).trim() === ''
				? 'Address Line 1 is required'
				: containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'addressLine2':
			return containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'city':
			return containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		case 'postalCode':
			return (value as string).trim() === ''
				? 'Postal Code is required'
				: containsSpecialChars(value as string)
				? 'Special characters are not allowed'
				: ''
		default:
			return ''
	}
}

// Generic filter function - Allow global search on tables
export const filterTableData = (data: any[], value: string) => {
	return data.filter((row) => {
		return Object.values(row).some((val) => {
			if (typeof val === 'string') {
				return val.toLowerCase().includes(value.toLowerCase())
			} else if (typeof val === 'number') {
				return val.toString().includes(value.toString())
			}
			return false
		})
	})
}

// Validate email
export const validateEmail = (email: string): boolean => {
	// Regular expression for validating email addresses
	const emailRegex = /^[^\s@]+@[A-Za-z0-9-]+\.[A-Za-z0-9-.]+$/
	return emailRegex.test(email)
}

// Convert hex to rgba
export function hexToRgba(hex: string, alpha: number): string {
	const hexWithoutHash = hex.startsWith('#') ? hex.slice(1) : hex
	const hexValue = parseInt(hexWithoutHash, 16)
	const r = (hexValue >> 16) & 255
	const g = (hexValue >> 8) & 255
	const b = hexValue & 255
	return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export function containsSpecialChars(str: string) {
	var specialChars = /[`!@#$%^&*()+=\[\]{};:"\\|,.<>\/?~]/
	return specialChars.test(str)
}

export function objectToStringForGet(objName: string, obj: any) {
	// This function makes an object to a string for searching - GetV2
	var filterText = ''
	var filters = [] as string[]
	Object.keys(obj).forEach((key: any) => {
		if (obj[key]) {
			filters.push(`${objName}.${key} = '${obj[key]}'`)
		}
	})
	filterText = filters.filter(Boolean).join(' & ')

	return filterText
}

// Remove leading zeroes in number textbox
export function RemoveLeadingZeroesInDecimal(e: { target: any }): string {
	while (e.target.value.charAt(0) === '0' && e.target.value.charAt(1) !== '.') {
		e.target.value = e.target.value.slice(1)
	}
	return e.target.value
}

export function RemoveLeadingZeroesInInteger(e: { target: any }): string {
	while (e.target.value.charAt(0) === '0') {
		e.target.value = e.target.value.slice(1)
	}
	return e.target.value
}

export function GetEmptyAccountInfo(): AccountInfo {
	var accInfo: AccountInfo = {
		environment: '',
		homeAccountId: '',
		localAccountId: '',
		tenantId: '',
		username: '',
		idToken: '',
		name: '',
		nativeAccountId: '',
	}
	return accInfo
}

// Caching Logic
// Set local storage caching
export const setLocalStorage = (key: string, value: any) => {
	localStorage.setItem(key, JSON.stringify(value))
}

// Get local storage caching
export const getLocalStorage = (key: string) => {
	const data = localStorage.getItem(key)
	return data ? JSON.parse(data) : null
}

// Remove local storage caching
export const removeLocalStorage = (key: string) => {
	localStorage.removeItem(key)
}

// Add to array on local storage caching
export const addToArrayInLocalStorage = (key: string, value: any) => {
	const existingArray = getLocalStorage(key) || []
	existingArray.push(value)
	setLocalStorage(key, existingArray)
}

// Remove from array on local storage caching
export const removeFromArrayInLocalStorage = (
	key: string,
	value: any,
	compareFn: (a: any, b: any) => boolean
) => {
	const existingArray = getLocalStorage(key) || []
	const newArray = existingArray.filter((item: any) => !compareFn(item, value))
	setLocalStorage(key, newArray)
}

// Update existing item in array from local storage caching
// Amend an item in an array on local storage
export const amendArrayItemInLocalStorage = (
	key: string,
	newValue: any,
	compareFn: (a: any, b: any) => boolean
) => {
	const existingArray = getLocalStorage(key) || []
	const updatedArray = existingArray.map((item: any) =>
		compareFn(item, newValue) ? newValue : item
	)
	setLocalStorage(key, updatedArray)
}

// Array.find and return the object
export const findArrayValue = <T>(
	array: T[],
	key: keyof T,
	value: any
): T | undefined => {
	return array.find((arr) => arr[key] === value)
}

// Handle on wheel event on type='number'
export const handleWheelEvent = (e: any) => {
	e.nativeEvent.returnValue = false
	e.target.blur()
}

export const isEmptyOrWhitespace = (str: string): boolean => {
	return str.trim() === ''
}

export const getEnumKeysAsArray = (
	enumObj: object,
	excludeNumericKeys: boolean,
	excludedKeys: Set<string>
): string[] => {
	// Get all keys from the enum
	var enumKeysAsArray: string[] = Object.keys(enumObj)

	if (excludeNumericKeys) {
		// Filter out numeric keys if specified
		enumKeysAsArray = enumKeysAsArray.filter((key: string) =>
			isNaN(Number(key))
		)
	}

	// Filter out any keys present in the excludedKeys set
	enumKeysAsArray = enumKeysAsArray.filter(
		(key: string) => !excludedKeys.has(key)
	)

	return enumKeysAsArray
}

// Order Term
export const getOrderTerm = (years: number, months: number): string => {
	const yearsString =
		years === 1 ? `${years} Year` : years > 1 ? `${years} Years` : ''
	const monthsString =
		months === 1 ? `${months} Month` : months > 1 ? `${months} Months` : ''

	// Convert to string and return
	return [yearsString, monthsString].filter(Boolean).join(' ')
}

// Function to get the enum value by key
export function getEnumValueByKey(
	enumObj: any,
	key: string
): number | undefined {
	return enumObj[key as keyof typeof enumObj]
}

// Function to get the enum value by key
export const getEnumKeyByValue = (enumObj: any, value: string) => {
	const keys = getEnumKeysAsArray(enumObj, true, new Set())
	for (const key of keys) {
		if (enumObj[key] === value) {
			return key
		}
	}

	// Return '' if no key matches
	return ''
}

export function onCheckPopup() {
	var hasPopUpsEnabled = false
	var windowName = 'checkPopUps'
	var popUp = window.open(
		'/popup-page',
		windowName,
		'width=1, height=1, left=1, top=1'
	)
	if (popUp == null || typeof popUp == 'undefined') {
		showPopUpError()
	} else {
		popUp.close()
		hasPopUpsEnabled = true
	}
	return hasPopUpsEnabled
}

export function showPopUpError() {
	showErrorToast(
		'Please allow pop-ups for this site in your browser before proceeding'
	)
}

export const formatDisplayName = (key: string): string => {
	// Add a space before each uppercase letter and capitalize the first letter
	return key
		.replace(/([A-Z])/g, ' $1')
		.replace(/^./, (str) => str.toUpperCase())
}

// Create filtered array
export const createSelectFiltersArray = (
	_orderFilterKey: string,
	selectedFilterList: SelectedFilters[]
): SelectedFilters[] => {
	const selectFilters: SelectedFilters[] = []

	if (isEmptyOrWhitespace(_orderFilterKey)) {
		return selectedFilterList
	}

	var enumKeys: string[] = []
	const excludeKeys: Set<string> = new Set()

	switch (_orderFilterKey) {
		case 'Order Status':
			enumKeys = getEnumKeysAsArray(OrderStatuses, false, excludeKeys)

			enumKeys.forEach((key: string) => {
				const formattedDisplayName = formatDisplayName(key)
				selectFilters.push({
					FilterCategory: OrderFilterKeyMappings.OrderStatusID,
					FilterID: getEnumValueByKey(OrderStatuses, key),
					FilterDisplayName: formattedDisplayName.trim(),
				})
			})
			break
		case 'Number Status':
			enumKeys = getEnumKeysAsArray(DDIStatuses, true, excludeKeys)

			enumKeys.forEach((key: string) => {
				const formattedDisplayName = formatDisplayName(key)
				selectFilters.push({
					FilterCategory: TNFilterKeyMappings.DDIStatusID,
					FilterID: getEnumValueByKey(DDIStatuses, key),
					FilterDisplayName: formattedDisplayName.trim(),
				})
			})
			break
		case 'Order Type':
			enumKeys = getEnumKeysAsArray(OrderTypes, true, excludeKeys)

			enumKeys.forEach((key: string) => {
				const formattedDisplayName = formatDisplayName(key)
				selectFilters.push({
					FilterCategory: OrderFilterKeyMappings.OrderTypeID,
					FilterID: getEnumValueByKey(OrderTypes, key),
					FilterDisplayName: formattedDisplayName.trim(),
				})
			})
			break

		case 'Ingress Service Type':
			enumKeys = getEnumKeysAsArray(ServiceTypes, true, excludeKeys)

			enumKeys.forEach((key: string) => {
				const formattedDisplayName = formatDisplayName(key)
				selectFilters.push({
					FilterCategory: TNFilterKeyMappings.ServiceTypeInID,
					FilterID: getEnumValueByKey(ServiceTypes, key),
					FilterDisplayName: formattedDisplayName.trim().replaceAll(' ', ''),
				})
			})
			break
		case 'Egress Service Type':
			enumKeys = getEnumKeysAsArray(ServiceTypes, true, excludeKeys)

			enumKeys.forEach((key: string) => {
				const formattedDisplayName = formatDisplayName(key)
				selectFilters.push({
					FilterCategory: TNFilterKeyMappings.ServiceTypeOutID,
					FilterID: getEnumValueByKey(ServiceTypes, key),
					FilterDisplayName: formattedDisplayName.trim().replaceAll(' ', ''),
				})
			})
			break
	}

	return selectFilters
}

// Value copy to clipboard
export const copyToClipboard = async (value: string) => {
	await navigator.clipboard.writeText(value)
}

export const getDropdownStylesWithScroll = (
	numberOfItemsInList: number,
	maxItemsToShow: number
): React.CSSProperties => {
	// Default menu styles
	const styles: React.CSSProperties = {
		overflowX: 'hidden',
		transition: 'none',
		maxWidth: '450px',
	}
	if (numberOfItemsInList > maxItemsToShow) {
		styles.maxHeight = '200px'
		styles.overflowY = 'auto'
	}
	return styles
}