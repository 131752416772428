import { useEffect, useRef, useState } from 'react'
import './AddOrder.scss'
import {
	Box,
	Divider,
	Alert,
	styled,
	Typography,
	MenuItem,
	Tooltip,
	Modal,
	Dialog,
	DialogTitle,
	DialogActions,
	Button,
	DialogContent,
	DialogContentText,
	useTheme,
	Checkbox,
} from '@mui/material'
import {
	BetaObject,
	DataResponse,
	OrderSummaryReq,
	OrderSummaryResponse,
	OrderTermModel,
	SkuDetails,
} from '../../../../../../utils/interfaces/APIModels'
import {
	getDropdownStylesWithScroll,
	getEnumKeysAsArray,
	getOrderTerm,
	isEmptyOrWhitespace,
	RemoveLeadingZeroesInInteger,
	showErrorToast,
	toAlphaString,
	toBeta,
	validateEmail,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { usePostBetaObjectOrderSummaryMutation } from '../../../../../../services/proxyAPIData'
import { StyledLoadingButton } from '../../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import {
	Customer,
	Order,
	OrderType,
	OrderSKU,
	SKUPartnerMap,
	PartnerBillingEntity,
} from '../../../../../../utils/interfaces/DBModels'
import { StyledSelect } from '../../../../../../styles/styledComponents/inputs/StyledSelect'
import { StyledTextField } from '../../../../../../styles/styledComponents/inputs/StyledTextField'
import AddOrderSummary from './AddOrderSummary/AddOrderSummary'
import Icon from '@mdi/react'
import { mdiTextBoxEditOutline } from '@mdi/js'
import {
	OrderSKUAutoRenewTerm,
	OrderStatuses,
	OrderTypes,
	PartnerBillingEntitiesEnum,
	ReturnTypes,
} from '../../../../../../utils/enums/enums'
import RenewalOrderType from './OrderTypeComponents/RenewalOrderType'
import TrueUpOrderType from './OrderTypeComponents/TrueUpOrderType'
import UseCrud from '../../../../../../utils/customHooks/APICalls/UseCrud'
import ModifyOrderType from './OrderTypeComponents/ModifyOrderType'
import NewOrderType from './OrderTypeComponents/NewOrderType'
import { useAppDispatch } from '../../../../../../store/hooks'
import { setPartnerIDToUse } from '../../../../../../store/reducers/reducers'
import { ErrorHandling } from '../../../../../../utils/customHooks/ErrorHandling/ErrorHandling'
import { OrderNotesMaximumCharactersAllowed } from '../../../../../../utils/constants/constants'

const AddOrder = ({
	customerList,
	skuPartnerMapList,
	standardNoRangeNoTermSKUs,
	handleCloseOrderFormModal,
}: {
	customerList: Customer[]
	skuPartnerMapList: SKUPartnerMap[]
	standardNoRangeNoTermSKUs: SKUPartnerMap[]
	handleCloseOrderFormModal: any
}) => {
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)
	const dispatch = useAppDispatch()
	const addErrorLog = ErrorHandling()

	//Order Details
	const [customerName, setCustomerName] = useState(
		'' as Customer['CustomerName']
	)
	const [customerPartnerReference, setCustomerPartnerReference] = useState('')
	const [customerID, setCustomerID] = useState('Please select a customer')
	const [purchaseOrder, setPurchaseOrder] = useState('')
	const [partnerOrderID, setPartnerOrderID] = useState('')
	const [salesAgentEmail, setSalesAgentEmail] = useState('')
	const [openModal, setOpenModal] = useState(false)

	//Order type
	const [orderType, setOrderType] = useState(-1)
	const [orderTypeSelected, setOrderTypeSelected] = useState('')
	const [previousOrderIDSelected, setPreviousOrderIDSelected] = useState(-1)
	const [showPreviousOrdersField, setShowPreviousOrdersField] = useState(false)
	const [orderTypeError, setOrderTypeError] = useState(false)
	const [isCoTerm, setIsCoTerm] = useState(false)
	const [hasOrderNotes, setHasOrderNotes] = useState(false)
	const [orderNotes, setOrderNotes] = useState('')
	const [addSKUToolTip, setAddSKUToolTip] = useState(
		'Please fill in the order details above.'
	)

	//Lists for display
	const [customerDropDownList, setCustomerDropDownList] = useState([] as any)
	const [skuDropDownList, setSkuDropDownList] = useState([] as any)
	const [orderTypeList, setOrderTypeList] = useState([] as OrderType[])
	const [customerExistingOrdersList, setCustomerExistingOrdersList] = useState(
		[] as Order[]
	)
	const [skusforPreviousOrderIDList, setSkusForPreviousOrderIDList] = useState(
		[] as OrderSKU[]
	)
	const [skuTermYearsList, setSkuTermYearsList] = useState([] as any)

	const [billingEntities, setBillingEntities] = useState<
		PartnerBillingEntity[]
	>([])
	const [selectedBillingEntity, setSelectedBillingEntity] = useState<number>(-1)
	const [isBillingEntityError, setIsBillingEntityError] =
		useState<boolean>(false)

	//Sku Details: multiple skus
	const [skuDetailsArray, setSkuDetailsArray] = useState([] as SkuDetails[])
	const [numberOfSkus, setNumberOfSkus] = useState<number>(1)
	const [skuDetailsErrorArray, setSkuDetailsErrorArray] = useState([] as any)
	const [deleteSkuIndex, setDeleteSkuIndex] = useState(0)
	const [disabledSkus, setDisabledSkus] = useState([] as any)
	const [selectedSku, setSelectedSku] = useState('')
	const addSku = true

	//dialog
	const [openDialog, setOpenDialog] = useState(false)
	const [dialogTitle, setDialogTitle] = useState('')
	const [dialogDescription, setDialogDescription] = useState('')

	//API
	const [orderSummary, setOrderSummary] = useState({} as OrderSummaryResponse)
	const [fetchSummaryData, setFetchSummaryData] = useState(false)
	const [apiCallSuccess, setApiCallSuccess] = useState(false)
	const [loadingButton, setLoadingButton] = useState(false)

	//Errors
	const [customerError, setCustomerError] = useState(false)
	const [partnerOrderReferenceError, setPartnerOrderReferenceError] =
		useState(false)
	const [purchaseOrderError, setPurchaseOrderError] = useState(false)
	const [salesAgentEmailError, setSalesAgentEmailError] = useState(false)
	const [previousOrderIDError, setPreviousOrderIDError] = useState(false)

	//flags
	const [showNewOrderTypeComponent, setShowNewOrderTypeComponent] =
		useState(false)
	const [showModifyOrderTypeComponent, setShowModifyOrderTypeComponent] =
		useState(false)
	const [showRenewalOrderTypeComponent, setShowRenewalOrderTypeComponent] =
		useState(false)
	const [showTrueUpOrderTypeComponent, setShowTrueUpOrderTypeComponent] =
		useState(false)
	const [disableReviewButton, setDisableReviewButton] = useState(true)
	const [disableAddSKUButton, setDisableAddSKUButton] = useState(true)

	const [getOrderSummary] = usePostBetaObjectOrderSummaryMutation()

	const previousOrderSelected = useRef<Order>()
	const hasPreviousOrders = useRef<boolean>(false)
	const previousSKUTerm = useRef<OrderTermModel>({ Months: 0, Years: 0 })
	const previousSKUActivationDate = useRef<Date | undefined>(undefined)
	const helperText = useRef<string>('')
	const autoRenewPeriodList: string[] = getEnumKeysAsArray(
		OrderSKUAutoRenewTerm,
		true,
		new Set()
	)

	// Theme
	const theme = useTheme()

	const { fetchData } = UseCrud()
	const maximumBillingEntitiesItems = 3

	//modal styling
	const modalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		bgcolor: '#f5f5f5',
		boxShadow: 24,
		p: 4,
		overflow: 'auto',
		maxHeight: '80%',
	}

	//info styling
	const InfoAlert = styled(Alert)({
		'& .MuiAlert-icon': {
			color: 'black',
		},
		backgroundColor: '#fff',
		color: 'black',
		fontSize: '14px',
	})

	useEffect(() => {
		setCustomerDropDownList(
			customerList.sort((a, b) =>
				a.CustomerName!.localeCompare(b.CustomerName!, undefined, {
					sensitivity: 'base',
				})
			)
		)

		setSkuDropDownList(skuPartnerMapList?.sort())

		var orderTypes = [] as OrderType[]
		for (var orderType in OrderTypes) {
			if (!isNaN(Number(orderType))) {
				orderTypes.push({
					OrderTypeID: Number(orderType),
					OrderTypeName: OrderTypes[orderType],
				})
			}
		}

		setOrderTypeList(
			orderTypes.sort((a, b) =>
				a.OrderTypeName!.localeCompare(b.OrderTypeName!, undefined, {
					sensitivity: 'base',
				})
			)
		)

		setSkuTermYearsList([1, 2, 3])

		var _billingEntities: PartnerBillingEntity[] = []
		for (var _billingEntity in PartnerBillingEntitiesEnum) {
			if (!isNaN(Number(_billingEntity))) {
				_billingEntities.push({
					PartnerBillingEntityID: Number(_billingEntity),
					PartnerBillingEntityName: PartnerBillingEntitiesEnum[_billingEntity],
				})
			}
		}

		_billingEntities = _billingEntities.sort((a, b) =>
			a.PartnerBillingEntityName!.localeCompare(
				b.PartnerBillingEntityName!,
				undefined,
				{
					sensitivity: 'base',
				}
			)
		)

		setBillingEntities(_billingEntities)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerList, skuPartnerMapList, orderType, customerID])

	// Rest Order Form
	const resetOrderDetails = () => {
		setCustomerPartnerReference('')
		setCustomerID('Please select a customer')
		setPurchaseOrder('')
		setPartnerOrderID('')
		setSalesAgentEmail('')
		setSelectedBillingEntity(-1)
		setOrderType(-1)
		setPreviousOrderIDSelected(-1)
		setOrderTypeSelected('')
		setAddSKUToolTip('Please fill in the order details above.')
		setIsBillingEntityError(false)
		setOrderTypeError(false)
		resetSKUDetails()

		hasPreviousOrders.current = false
		previousSKUTerm.current = { Months: 0, Years: 0 }
		previousSKUActivationDate.current = undefined
		helperText.current = ''
	}

	// Reset SKU Details
	const resetSKUDetails = () => {
		if (skuDetailsArray) {
			setSelectedSku('')
			setDeleteSkuIndex(0)
			setNumberOfSkus(0)
			setDisabledSkus([])
			setSkuDetailsErrorArray([])
			setSkuDetailsArray([])
			setDisableReviewButton(true)
		}
	}

	const areRequiredFieldsValid = (): boolean => {
		// Check if customer is selected
		if (customerID === 'Please select a customer') {
			setAddSKUToolTip('Please select a Customer.')
			helperText.current = 'Please ensure that all required fields are filled'
			return false
		}

		// Check if other required fields are filled
		if (
			isEmptyOrWhitespace(partnerOrderID) ||
			isEmptyOrWhitespace(purchaseOrder) ||
			selectedBillingEntity === -1 ||
			orderType === -1
		) {
			setPartnerOrderReferenceError(isEmptyOrWhitespace(partnerOrderID))
			setPurchaseOrderError(isEmptyOrWhitespace(purchaseOrder))
			setIsBillingEntityError(selectedBillingEntity === -1)
			setOrderTypeError(orderType === -1)
			helperText.current = 'Please ensure that all required fields are filled'
			setAddSKUToolTip('Please ensure that all required fields are filled .')
			return false
		}

		// If Co Term or orderType !== New, check if previous order ID selected
		if (
			(isCoTerm || orderType !== OrderTypes.New) &&
			previousOrderIDSelected === -1
		) {
			setAddSKUToolTip('Please select a Previous Order.')
			helperText.current = 'Please select a Previous Order.'
			return false
		}

		return true
	}

	// Handle Functions
	const handleCustomerChange = async (e: { target: any }) => {
		const customerIDSelected = e.target.value
		if (customerIDSelected !== undefined) {
			if (customerID !== customerIDSelected) {
				// Reset OrderType & SKU Details
				setOrderType(-1)
				setPreviousOrderIDSelected(-1)
				setOrderTypeSelected('')
				setIsCoTerm(false)
				setAddSKUToolTip(
					'Please select an order type from the Order Details section above.'
				)
				setShowPreviousOrdersField(false)
				resetSKUDetails()
			}

			helperText.current = ''
			// set the values for customerID
			setCustomerID(customerIDSelected)
			await fetchCustomersExistingOrders(customerIDSelected)

			// find the associated customer name
			const selectedCustomer = customerDropDownList.find(
				(customer: any) => customer?.CustomerID === customerIDSelected
			)

			if (selectedCustomer !== undefined) {
				setCustomerName(selectedCustomer.CustomerName)
				setCustomerPartnerReference(selectedCustomer.CustomerPartnerReference)
			}

			setCustomerError(false)
		}
	}

	const handlePartnerOrderReferenceChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var newValue = e.target.value
		setPartnerOrderID(newValue)
		helperText.current = ''

		//Validation
		if (newValue === '') {
			setPartnerOrderReferenceError(true)
		} else {
			setPartnerOrderReferenceError(false)
		}
	}

	const handlePurchaseOrderChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var purchaseOrderValue = e.target.value
		setPurchaseOrder(purchaseOrderValue)
		helperText.current = ''

		// Validation
		if (purchaseOrderValue === '') {
			setPurchaseOrderError(true)
		} else {
			setPurchaseOrderError(false)
		}
	}

	const handleBillingEntityChange = (_selectedBillingEntity: number) => {
		helperText.current = ''
		setIsBillingEntityError(_selectedBillingEntity === -1)
		setSelectedBillingEntity(_selectedBillingEntity)
	}

	const handleOrderTypeChange = async (e: { target: any }) => {
		// Check if customer is selected
		if (customerID === 'Please select a customer') {
			setAddSKUToolTip('Please select a Customer.')
			helperText.current = 'Please select a customer'
			return false
		}

		// Ensure required fields are filled
		if (
			isEmptyOrWhitespace(partnerOrderID) ||
			isEmptyOrWhitespace(purchaseOrder) ||
			selectedBillingEntity === -1
		) {
			setPartnerOrderReferenceError(isEmptyOrWhitespace(partnerOrderID))
			setPurchaseOrderError(isEmptyOrWhitespace(purchaseOrder))
			setIsBillingEntityError(selectedBillingEntity === -1)
			helperText.current = 'Please ensure that all required fields are filled'
			setAddSKUToolTip('Please ensure that all required fields are filled .')
			return false
		}

		resetSKUDetails()

		var selectedOrderType = Number(e.target.value)
		setOrderType(selectedOrderType)
		helperText.current = ''

		//get the name of the order type selected
		var getOrderType: any = orderTypeList.filter(
			(x) => x.OrderTypeID === selectedOrderType
		)
		setOrderTypeSelected(getOrderType[0].OrderTypeName)

		switch (selectedOrderType) {
			case OrderTypes.New:
				setShowNewOrderTypeComponent(true)
				setShowModifyOrderTypeComponent(false)
				setShowRenewalOrderTypeComponent(false)
				setShowTrueUpOrderTypeComponent(false)
				setPreviousOrderIDSelected(-1)
				setNumberOfSkus(1)
				setIsCoTerm(false)
				break
			case OrderTypes.Modify:
				setShowModifyOrderTypeComponent(true)
				setShowNewOrderTypeComponent(false)
				setShowRenewalOrderTypeComponent(false)
				setShowTrueUpOrderTypeComponent(false)
				setPreviousOrderIDSelected(-1)
				setIsCoTerm(true)
				break
			case OrderTypes.Renewal:
				setShowRenewalOrderTypeComponent(true)
				setShowNewOrderTypeComponent(false)
				setShowModifyOrderTypeComponent(false)
				setShowTrueUpOrderTypeComponent(false)
				setPreviousOrderIDSelected(-1)
				setIsCoTerm(true)
				break
			case OrderTypes.TrueUp:
				setShowTrueUpOrderTypeComponent(true)
				setShowNewOrderTypeComponent(false)
				setShowModifyOrderTypeComponent(false)
				setShowRenewalOrderTypeComponent(false)
				setPreviousOrderIDSelected(-1)
				setIsCoTerm(true)
				break
		}

		if (
			(selectedOrderType === OrderTypes.Modify ||
				selectedOrderType === OrderTypes.Renewal ||
				selectedOrderType === OrderTypes.TrueUp) &&
			customerExistingOrdersList.length > 0
		) {
			setShowPreviousOrdersField(true)
			setAddSKUToolTip('Please select a previous order ID.')
		} else {
			setShowPreviousOrdersField(false)
			setAddSKUToolTip('Please select a SKU and fill in the required fields')
		}

		// Validation
		if (selectedOrderType === 0) {
			setOrderTypeError(true)
		} else {
			setOrderTypeError(false)
		}
	}

	//handle existing order chosen
	const handleExistingOrderChosen = async (e: { target: any }) => {
		// Reset form if new order chosen without CoTerm and Previous Order field showing
		if (orderType === OrderTypes.New && !isCoTerm) {
			resetOrderDetails()
			setDisableAddSKUButton(true)
			return
		}

		resetSKUDetails()

		var selectedOrderID = e.target.value
		setPreviousOrderIDSelected(selectedOrderID)

		//make api call to get the skus for that order id selected
		await fetchSkusforPreviousOrderIDSelected(selectedOrderID)

		//validation
		if (selectedOrderID === 0) {
			setPreviousOrderIDError(true)
		} else {
			setPreviousOrderIDError(false)
		}

		setNumberOfSkus(1)
		setAddSKUToolTip('Please select a SKU and fill in the required fields')

		helperText.current = ''
	}

	const handleSalesAgentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setSalesAgentEmail(newValue)

		//email validation
		var isValid = validateEmail(newValue)
		if (newValue.trim() === '' || isValid) {
			setSalesAgentEmailError(false)
		} else {
			setSalesAgentEmailError(true)
		}
	}

	const handleSkuChange = (e: { target: any }, index: any) => {
		var selectedSkuValue = e.target.value
		var selectedSkuIndex = index

		//added skus
		const updatedSkuDetailsArray = [...skuDetailsArray]

		//disabled skus
		let latestDisabledSkus = [...disabledSkus]

		//is an existing sku selection being changed
		var existingSkuToRemove = latestDisabledSkus.find(
			(item, itemIndex) => itemIndex === selectedSkuIndex
		)

		if (existingSkuToRemove) {
			//replace the value of sku based on the index in the disabledSkusArray
			latestDisabledSkus[selectedSkuIndex] = selectedSkuValue
			setDisabledSkus(latestDisabledSkus)
		} else {
			//disable sku once selected (works)
			setDisabledSkus([...latestDisabledSkus, selectedSkuValue])
		}

		//store selected value
		setSelectedSku(selectedSkuValue)

		if (!updatedSkuDetailsArray[index]) {
			updatedSkuDetailsArray[index] = {}
		}

		updatedSkuDetailsArray[index].SkuTypeID = selectedSkuValue
		// Check if SKU is StandardNoRangeNoTerm
		const isNoTermSkuPricingType = standardNoRangeNoTermSKUs.find(
			(x) => x.SKUTypeID === selectedSkuValue
		)
		// Determine wether SKU has a term using StandardNoRangeNoTerm
		updatedSkuDetailsArray[index].HasSkuTerm =
			isNoTermSkuPricingType === undefined

		// OrderType !== New and SKU is not StandardNoTermNoRange, Get SKUTerm
		if (orderType !== OrderTypes.New && isNoTermSkuPricingType === undefined) {
			var _skuTerm = ''
			const _originalSKU = skusforPreviousOrderIDList.find(
				(sku) => sku.SKUTypeID === selectedSkuValue
			)
			const _originalSkuTermList = _originalSKU?.OrderSKUTermList

			if (_originalSkuTermList !== undefined) {
				const months = Number(_originalSkuTermList[0].Months)
				const years = Number(_originalSkuTermList[0].Years)
				_skuTerm = getOrderTerm(years, months)
			}

			if (!isEmptyOrWhitespace(_skuTerm)) {
				updatedSkuDetailsArray[index].SkuTerm = _skuTerm
			}
		}

		setSkuDetailsArray(updatedSkuDetailsArray)
		toggleReviewOrderButton(updatedSkuDetailsArray)
	}

	const handleQuantityChange = (e: { target: any }, index: any) => {
		var quantityValue = Number(RemoveLeadingZeroesInInteger(e))

		//add quantity to skuDetails list
		const updatedSkuDetailsArray = [...skuDetailsArray]

		if (!Number.isInteger(quantityValue) || quantityValue < 1) {
			//set an error state
			const updatedSkuErrors = [...skuDetailsErrorArray]
			updatedSkuErrors[index] = {
				...updatedSkuErrors[index],
				Quantity: true,
			}
			setSkuDetailsErrorArray(updatedSkuErrors)
			//also update the sku details array to whatever they select
			//create empty object if its the first index
			if (!updatedSkuDetailsArray[index]) {
				updatedSkuDetailsArray[index] = {}
			}

			updatedSkuDetailsArray[index].Quantity = quantityValue
			setSkuDetailsArray(updatedSkuDetailsArray)
		} else {
			//clear the error state for quantity
			const updatedSkuErrors = [...skuDetailsErrorArray]
			updatedSkuErrors[index] = {
				...updatedSkuErrors[index],
				Quantity: false,
			}
			setSkuDetailsErrorArray(updatedSkuErrors)

			//create empty object if its the first index
			if (!updatedSkuDetailsArray[index]) {
				updatedSkuDetailsArray[index] = {}
			}

			updatedSkuDetailsArray[index].Quantity = quantityValue
			setSkuDetailsArray(updatedSkuDetailsArray)
		}
		toggleReviewOrderButton(updatedSkuDetailsArray)
	}

	const handleSkuTermChange = (e: { target: any }, index: any) => {
		var skuTerm = Number(e.target.value)

		const updatedSkuDetailsArray = [...skuDetailsArray]

		//check if skuterm is less than 0 or decimal
		if (!Number.isInteger(skuTerm) || skuTerm < 0) {
			//set an error state
			const updatedSkuErrors = [...skuDetailsErrorArray]
			updatedSkuErrors[index] = {
				...updatedSkuErrors[index],
				SkuTerm: true,
			}
			setSkuDetailsErrorArray(updatedSkuErrors)
			//also update the sku details array to whatever they select
			//create empty object if its the first index
			if (!updatedSkuDetailsArray[index]) {
				updatedSkuDetailsArray[index] = {}
			}

			updatedSkuDetailsArray[index].SkuTerm = skuTerm
			setSkuDetailsArray(updatedSkuDetailsArray)
		} else {
			//first clear the error state
			const updatedSkuErrors = [...skuDetailsErrorArray]
			updatedSkuErrors[index] = {
				...updatedSkuErrors[index],
				SkuTerm: false,
			}
			setSkuDetailsErrorArray(updatedSkuErrors)

			//create empty object if its the first index
			if (!updatedSkuDetailsArray[index]) {
				updatedSkuDetailsArray[index] = {}
			}

			updatedSkuDetailsArray[index].SkuTerm = skuTerm
			setSkuDetailsArray(updatedSkuDetailsArray)
		}
		toggleReviewOrderButton(updatedSkuDetailsArray)
	}

	const handleAutoRenewTermChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		var autoRenewTerm = e.target.value
		const updatedSkuDetailsArray = [...skuDetailsArray]

		//first clear the error state
		const updatedSkuErrors = [...skuDetailsErrorArray]
		updatedSkuErrors[index] = {
			...updatedSkuErrors[index],
			AutoRenewMonths: false,
		}
		setSkuDetailsErrorArray(updatedSkuErrors)

		//create empty object if its the first index
		if (!updatedSkuDetailsArray[index]) {
			updatedSkuDetailsArray[index] = {}
		}

		updatedSkuDetailsArray[index].AutoRenewMonths = autoRenewTerm
		updatedSkuDetailsArray[index].OrderSKUTermAutoRenewID = Number(
			OrderSKUAutoRenewTerm[autoRenewTerm as any]
		)
		setSkuDetailsArray(updatedSkuDetailsArray)

		toggleReviewOrderButton(updatedSkuDetailsArray)
	}

	// Handle Co-Term toggled
	const handleCoTermCheckboxChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const isChecked = e.target.checked

		if (isChecked) {
			// Check if required fields are filled
			if (!areRequiredFieldsValid()) {
				return
			}

			setIsCoTerm(true)
			setShowPreviousOrdersField(true)
			setAddSKUToolTip('Please select a previous order ID.')
			if (previousOrderIDSelected === -1) {
				resetSKUDetails()
			}
		} else {
			setIsCoTerm(false)
			if (
				orderType === OrderTypes.New ||
				customerExistingOrdersList.length === 0
			) {
				setShowPreviousOrdersField(false)
				setPreviousOrderIDSelected(-1)
				resetSKUDetails()
				setNumberOfSkus(1)
				setAddSKUToolTip('Please select a SKU and fill in the required fields')
			}
		}
		setDisableAddSKUButton(true)
	}

	// Handle Add Order Notes toggled
	const handleOrderNotesCheckboxChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		if (e.target.checked) {
			setHasOrderNotes(true)
		} else {
			setHasOrderNotes(false)
			setOrderNotes('')
		}
	}

	//handle delete dialog
	const deleteConfirmation = (index: any) => {
		setDialogTitle('Caution: This is an irreversible action!')
		setDialogDescription('Are you sure you want to delete this SKU?')
		setDeleteSkuIndex(index)
		setOpenDialog(true)
	}

	//delete skus based on the selected index
	const deleteSku = () => {
		setNumberOfSkus((prevCount) => prevCount - 1)
		const updatedSkuArray = [...skuDetailsArray]
		updatedSkuArray.splice(deleteSkuIndex, 1)
		setSkuDetailsArray(updatedSkuArray)
		toggleReviewOrderButton(updatedSkuArray)

		//enable the deleted sku again
		const deletedSku = skuDetailsArray[deleteSkuIndex]?.SkuTypeID
		var latestDisabledSkus = [...disabledSkus]
		if (deletedSku) {
			if (latestDisabledSkus.includes(deletedSku)) {
				var updatedDisableSkuList = latestDisabledSkus.filter(
					(sku) => sku !== deletedSku
				)
				setDisabledSkus(updatedDisableSkuList)
			}
		}

		setOpenDialog(false)
	}

	//show sku details
	const displaySkuDetails = async () => {
		setNumberOfSkus((prevCount) => prevCount + 1)

		if (numberOfSkus >= 1) {
			//check if the sku already exists in updatedSkuDetailsArray
			const getLatestSkuDetailsArry = [...skuDetailsArray]

			if (
				getLatestSkuDetailsArry.some((item) => item.SkuTypeID === selectedSku)
			) {
				//disable what was already selected
				if (!disabledSkus.includes(selectedSku)) {
					setDisabledSkus([...disabledSkus, selectedSku])
				}
			}
			setDisableReviewButton(true)
		}
	}

	const deleteDialog = () => {
		return (
			<>
				<Dialog
					open={openDialog}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{dialogDescription}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setOpenDialog(false)}
							sx={{ color: '#db3d47' }}>
							Cancel
						</Button>
						<Button onClick={deleteSku} autoFocus>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</>
		)
	}

	// Handle  Add SKU button toggle
	const toggleAddSkuButton = (_disableReviewOrder: boolean) => {
		// Validation logic
		if (
			purchaseOrder.length === 0 ||
			customerName?.length === 0 ||
			orderType === -1
		) {
			setDisableAddSKUButton(true)
			return
		}

		const otherOtherTypes =
			orderType === OrderTypes.Renewal ||
			orderType === OrderTypes.Modify ||
			orderType === OrderTypes.TrueUp

		if (orderType === OrderTypes.New || otherOtherTypes) {
			// Handle New Orders with or without coTerm
			if (orderType === OrderTypes.New) {
				if (numberOfSkus === skuDropDownList.length) {
					setDisableAddSKUButton(true)
					setAddSKUToolTip(
						'There are no additional SKUs available for selection.'
					)
					return
				}
			}

			// Handle Other Orders (skip new orders with coTerm)
			if (previousOrderIDSelected !== -1 && orderType !== OrderTypes.New) {
				if (numberOfSkus === skusforPreviousOrderIDList.length) {
					setDisableAddSKUButton(true)
					setAddSKUToolTip(
						'There are no additional SKUs available for selection.'
					)
					return
				}
			}
			if (!_disableReviewOrder) {
				setAddSKUToolTip('Add another SKU')
			} else {
				setAddSKUToolTip('Please select a SKU and fill in the required fields')
			}

			setDisableAddSKUButton(_disableReviewOrder)
		}
	}

	// Function to check if SKUs with Term have valid properties
	const areSkusWithTermValid = (skus: SkuDetails[]): boolean =>
		skus.every(
			(sku) =>
				sku.SkuTypeID !== undefined &&
				Number(sku.Quantity) > 0 &&
				Number(sku.SkuTerm) > 0
		)

	// Function to check if SKUs with No Term have valid properties
	const areSkusWithNoTermValid = (skus: SkuDetails[]): boolean =>
		skus.every((sku) => sku.SkuTypeID !== undefined && Number(sku.Quantity) > 0)

	// Handle Review button toggle
	const toggleReviewOrderButton = (updatedSKUDetails: SkuDetails[]) => {
		var disableReviewOrder = true

		// Handle New & Renewal Orders
		if (orderType === OrderTypes.New || orderType === OrderTypes.Renewal) {
			if (updatedSKUDetails) {
				const skusWithTerm = updatedSKUDetails.filter((sku) => sku.HasSkuTerm)
				const skusWithNoTerm = updatedSKUDetails.filter(
					(sku) => !sku.HasSkuTerm
				)

				if (!isCoTerm && skusWithTerm.length > 0 && skusWithNoTerm.length > 0) {
					disableReviewOrder =
						!areSkusWithTermValid(skusWithTerm) ||
						!areSkusWithNoTermValid(skusWithNoTerm)
				} else if (!isCoTerm && skusWithTerm.length > 0) {
					disableReviewOrder = !areSkusWithTermValid(skusWithTerm)
				} else if (isCoTerm && previousOrderIDSelected > 0) {
					disableReviewOrder = !areSkusWithNoTermValid(updatedSKUDetails)
				} else {
					disableReviewOrder = !areSkusWithNoTermValid(skusWithNoTerm)
				}
			}
		}

		// Handle Modify & TrueUp Orders
		if (orderType === OrderTypes.Modify || orderType === OrderTypes.TrueUp) {
			if (updatedSKUDetails) {
				disableReviewOrder = !areSkusWithNoTermValid(updatedSKUDetails)
			}
		}

		setDisableReviewButton(disableReviewOrder)
		toggleAddSkuButton(disableReviewOrder)
	}

	const disableDeleteButtonForNewOrRenewalOrderType = (index: number) => {
		if (
			!skuDetailsArray[index]?.SkuTypeID ||
			!skuDetailsArray[index]?.Quantity
		) {
			return true
		} else {
			return false
		}
	}

	const disableDeleteButtonForModifyOrTrueUpOrderType = (index: number) => {
		if (
			!skuDetailsArray[index]?.SkuTypeID ||
			!skuDetailsArray[index]?.Quantity
		) {
			return true
		} else {
			return false
		}
	}

	const handleOpenModal = () => {
		setOpenModal(true)
		fetchOrderSummary()
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	// CoTerm, set current SKU term equal to previous Order SKU term
	const setSKUTerm = () => {
		var skuTerm = ''
		if (isCoTerm) {
			skuTerm = getOrderTerm(
				Number(previousSKUTerm.current.Years),
				Number(previousSKUTerm.current.Months)
			)

			if (isEmptyOrWhitespace(skuTerm)) {
				if (previousOrderSelected.current) {
					if (previousOrderSelected.current?.OrderTermList) {
						const { Months, Years } =
							previousOrderSelected.current.OrderTermList[0]
						const orderTermYears = Years !== undefined && Years > 0 ? Years : 1
						skuTerm = getOrderTerm(orderTermYears, Number(Months))

						previousSKUTerm.current.Months = Months
						previousSKUTerm.current.Years = orderTermYears
					}
				}
			}
		}

		// Set SKU(s) term to the Previous Order SKU Term
		if (skusforPreviousOrderIDList.length > 0) {
			skuDetailsArray.forEach((sku: SkuDetails) => {
				if (sku.HasSkuTerm) {
					skuTerm = isCoTerm ? skuTerm : sku.SkuTerm + ''
					sku.SkuTerm = skuTerm[0]
				}
			})
		}
	}

	// Get previous order term
	const getPreviousOrderSKUTerm = (previousSKU: OrderSKU[]) => {
		const index = 0
		var months = 0
		var years = 0

		// Find SKU with ActivationDate and extract SkuTermEndDate, Months and Years
		const activeSku = previousSKU.find(
			(sku: OrderSKU) => sku.ActivationDate != null
		)

		if (activeSku !== undefined) {
			if (activeSku.OrderSKUTermList) {
				months = Number(activeSku.OrderSKUTermList[index].Months)
				years = Number(activeSku.OrderSKUTermList[index].Years)
			}
			previousSKUActivationDate.current = activeSku.SkuTermEndDate
			previousSKUTerm.current.Months = months
			previousSKUTerm.current.Years = years
			return
		}
		// Extract Months and Years from first sku (previousSKUList has no active sku)
		const skusWithTermList = previousSKU.filter(
			(sku: OrderSKU) => Number(sku.Price) > 0
		)
		var sku: OrderSKU = {}
		if (skusWithTermList !== undefined && skusWithTermList.length > 0) {
			sku = skusWithTermList[index]
			if (sku.OrderSKUTermList) {
				months = Number(sku.OrderSKUTermList[index].Months)
				years = Number(sku.OrderSKUTermList[index].Years)
			}
			previousSKUActivationDate.current = sku.SkuTermEndDate
			previousSKUTerm.current.Months = months
			previousSKUTerm.current.Years = years
		}
	}

	// *********************************************************** START API Calls *********************************************************** //

	//POST: Fetch summary screen data
	const fetchOrderSummary = async () => {
		setFetchSummaryData(true)
		setLoadingButton(true)
		setSKUTerm()

		var _partnerBillingEntity = PartnerBillingEntitiesEnum[
			selectedBillingEntity
		].replaceAll(' ', '')
		var orderSummaryReq: OrderSummaryReq = {
			PartnerOrderID: partnerOrderID,
			CustomerName: customerName,
			PurchaseOrder: purchaseOrder,
			OrderType: orderTypeSelected,
			PreviousOrderID: previousOrderIDSelected,
			SalesAgentEmail: salesAgentEmail,
			PartnerBillingEntity: _partnerBillingEntity,
			SkuDetails: skuDetailsArray,
		}

		if (isCoTerm) {
			orderSummaryReq.PreviousOrderID = previousOrderIDSelected
		}

		var betaDataObj = await toBeta(orderSummaryReq)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		dispatch(setPartnerIDToUse(partnerID))

		await getOrderSummary(betaObj)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as OrderSummaryResponse

					if (dataResponse) {
						var orderSummaryResponse: OrderSummaryResponse = {
							PartnerOrderID: dataResponse.PartnerOrderID,
							CustomerName: dataResponse.CustomerName,
							PurchaseOrder: dataResponse.PurchaseOrder,
							OrderType: dataResponse.OrderType,
							PreviousOrderID: dataResponse.PreviousOrderID,
							SalesAgentEmail: dataResponse.SalesAgentEmail,
							PartnerBillingEntityID: dataResponse.PartnerBillingEntityID,
							SipcomBillingEntityID: dataResponse.SipcomBillingEntityID,
							SkuDetails: dataResponse.SkuDetails,
						}

						setOrderSummary(orderSummaryResponse)
						setApiCallSuccess(true)
					}
				}
			})
			.catch(async (response) => {
				try {
					setApiCallSuccess(false)
					var parsedResponse = await toAlphaString(response.data.Content)

					if (parsedResponse) {
						showErrorToast(
							'There was an issue retrieving your order summary information. Please ensure there is a pricing type, deal range and pricebook mapped to the SKU(s) you have chosen.'
						)
					} else {
						showErrorToast(
							'Something went wrong while retrieving your order summary.'
						)
					}
					await addErrorLog({
						ActionPerformed: `Get Order Summary error: AddOrder.tsx - Generic Portal`,
						ErrorInfo: `Get Order Summary error: ${parsedResponse}. Request: ${JSON.stringify(
							orderSummaryReq
						)}`,
					})
				} catch (error) {
					showErrorToast('Something went wrong while processing your request.')
					setApiCallSuccess(false)
					await addErrorLog({
						ActionPerformed: `Get Order Summary error: AddOrder.tsx - Generic Portal`,
						ErrorInfo: `Get Order Summary error: ${JSON.stringify(
							error
						)}. Request: ${JSON.stringify(orderSummaryReq)}`,
					})
				}
			})

		setFetchSummaryData(false)
		setLoadingButton(false)
	}

	//GET: Existing orders for the customer selected
	const fetchCustomersExistingOrders = async (customerID: any) => {
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'AddOrder.tsx: fetchCustomersExistingOrders()',
			QueryURL: `GetV2?Params=Order.Where(Order.CustomerID='${customerID}')`,
			ErrorMessage: `An error occurred when getting previous orders linked to this customer id: ${customerID}`,
			ShowErrorToast: true,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (Number(dataResponse.Count) > 0) {
			var existingOrdersForCustomer = dataResponse.Obj.OrderList as Order[]

			var filteredOrderIDs: Order[] = existingOrdersForCustomer.filter(
				(x) =>
					(x.OrderTypeID === OrderTypes.New &&
						(x.OrderStatusID === OrderStatuses.InProgress ||
							x.OrderStatusID === OrderStatuses.Active ||
							x.OrderStatusID === OrderStatuses.Complete)) ||
					x.OrderTypeID === OrderTypes.Renewal
			)

			// Customer has existing orders
			hasPreviousOrders.current = true

			setCustomerExistingOrdersList(
				filteredOrderIDs.sort((a, b) =>
					(a?.PartnerOrderID + '')!.localeCompare(
						(b?.PartnerOrderID + '')!,
						undefined,
						{
							sensitivity: 'base',
						}
					)
				)
			)
		} else {
			//no existing orders were found
			setShowPreviousOrdersField(false)
			// No existing orders were found, disable coTerm (can't be linked to previous order)
			hasPreviousOrders.current = false

			var filterOrderTypeList: OrderType[] = orderTypeList.filter(
				(x) => x.OrderTypeID === OrderTypes.New
			)

			setOrderTypeList(filterOrderTypeList)
		}
	}

	//GET: Order Skus for PreviousOrderID selected
	const fetchSkusforPreviousOrderIDSelected = async (selectedOrderID: any) => {
		var dataResponse = (await fetchData({
			FileAndFunctionName:
				'AddOrder.tsx: fetchSkusforPreviousOrderIDSelected()',
			QueryURL: `GetV2?Params=Order.OrderTerm.OrderSKU.OrderSKUTerm.Where(Order.OrderID='${selectedOrderID}')`,
			ErrorMessage: `An error occurred when getting the skus linked to the previous order id selected`,
			ShowErrorToast: true,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (Number(dataResponse.Count) > 0) {
			const existingOrder: Order = dataResponse.Obj.OrderList[0]
			var existingSkusForPreviousOrderIDSelected =
				existingOrder.OrderSKUList as OrderSKU[]

			var existingSkulist = [] as OrderSKU[]
			//push all sku ids found into the list
			for (var i = 0; i < existingSkusForPreviousOrderIDSelected.length; i++) {
				var skuTypeId: OrderSKU = {
					SKUTypeID: existingSkusForPreviousOrderIDSelected[i].SKUTypeID,
					ActivationDate:
						existingSkusForPreviousOrderIDSelected[i].ActivationDate,
					OrderSKUStatusID:
						existingSkusForPreviousOrderIDSelected[i].OrderSKUStatusID,
					SkuTermEndDate:
						existingSkusForPreviousOrderIDSelected[i].SkuTermEndDate,
					Price: existingSkusForPreviousOrderIDSelected[i].Price,
					OrderSKUTermList:
						existingSkusForPreviousOrderIDSelected[i].OrderSKUTermList,
				}

				existingSkulist.push(skuTypeId)
			}

			existingSkusForPreviousOrderIDSelected.forEach((sku) => {
				existingSkulist.push({
					SKUTypeID: sku.SKUTypeID,
					ActivationDate: sku.ActivationDate,
					OrderSKUStatusID: sku.OrderSKUStatusID,
					SkuTermEndDate: sku.SkuTermEndDate,
					Price: sku.Price,
					OrderSKUTermList: sku.OrderSKUTermList,
				})
			})

			// If a sku is in existingSkulist, remove it from the otherSKUsList below
			const otherSKUsList = skuDropDownList.filter(
				(x: string) => !existingSkulist.some((y) => y.SKUTypeID === x)
			)

			if (otherSKUsList.length > 0) {
				otherSKUsList.forEach((sku: string) => {
					existingSkulist.push({
						SKUTypeID: sku,
					})
				})
			}

			// Get previous order term
			getPreviousOrderSKUTerm(existingSkulist)
			setSkusForPreviousOrderIDList(
				existingSkulist.sort((a, b) =>
					a.SKUTypeID!.localeCompare(b.SKUTypeID!, undefined, {
						sensitivity: 'base',
					})
				)
			)
			previousOrderSelected.current = existingOrder
		}
	}

	// *********************************************************** END API Calls *********************************************************** //

	return (
		<>
			<Box className='order-form-container'>
				{/* Heading */}
				<Box className='order-form-header'>
					<Icon
						path={mdiTextBoxEditOutline}
						size={2}
						style={{ color: theme.palette.primary.main }}
					/>
					<Typography component='h1' style={{ fontSize: '25px' }}>
						Add New Order
						<Typography component='p'>You can add a new order here:</Typography>
					</Typography>
				</Box>

				<Divider />

				{/* Form Content */}
				<Box className='order-form-content-add'>
					<Box className='order-form-pd'>
						<Box className='order-form-section-headers'>
							<Typography variant='h6' style={{ whiteSpace: 'nowrap' }}>
								Order Details:{' '}
							</Typography>
							<InfoAlert severity='info' style={{ padding: '0' }}>
								Please fill in the necessary details below.
							</InfoAlert>
						</Box>

						{/* customer drop down */}
						<Box className='order-form-input'>
							<Typography component='p'>
								Customer:{' '}
								<Typography component='span' className='required'>
									{' '}
									*
								</Typography>
							</Typography>
							<StyledSelect
								fullWidth
								value={customerID}
								error={customerError}
								type='text'
								onChange={handleCustomerChange}>
								<MenuItem disabled value={'Please select a customer'}>
									Please select a customer
								</MenuItem>
								{customerList?.map((item, index) => (
									<MenuItem key={index} value={item.CustomerID}>
										{item.CustomerName}
									</MenuItem>
								))}
							</StyledSelect>
						</Box>

						{/* Partner Order Reference  */}
						<Box className='order-form-input'>
							<Typography component='p'>
								{' '}
								Partner Order Reference:{' '}
								<Typography component='span' className='required'>
									*
								</Typography>
							</Typography>
							<StyledTextField
								fullWidth
								required
								error={partnerOrderReferenceError}
								helperText={
									partnerOrderReferenceError && 'This is a required field'
								}
								value={partnerOrderID}
								onChange={handlePartnerOrderReferenceChange}
								type='text'
								placeholder='Please enter a partner order reference'
							/>
						</Box>

						{/* Purchase Order */}
						<Box className='order-form-input'>
							<Typography component='p'>
								Purchase Order:
								<Typography component='span' className='required'>
									*
								</Typography>
							</Typography>
							<StyledTextField
								fullWidth
								required
								error={purchaseOrderError}
								helperText={purchaseOrderError && 'This is a required field'}
								value={purchaseOrder}
								onChange={handlePurchaseOrderChange}
								type='text'
								placeholder='Please enter a purchase order'
							/>
						</Box>

						{/* Sales Agent Email */}
						<Box className='order-form-input'>
							<Typography component='p'> Sales Agent Email: </Typography>
							<StyledTextField
								fullWidth
								error={salesAgentEmailError}
								helperText={
									salesAgentEmailError && 'Please enter a valid email address'
								}
								value={salesAgentEmail}
								onChange={handleSalesAgentChange}
								type='text'
								placeholder='Enter your email address'
							/>
						</Box>

						{/* Billing Entity */}
						<Box className='order-form-input'>
							<Typography component='p'>
								Billing Entity:{' '}
								<Typography component='span' className='required'>
									*
								</Typography>
							</Typography>
							<StyledSelect
								fullWidth
								error={isBillingEntityError}
								value={selectedBillingEntity}
								type='text'
								onChange={(e) =>
									handleBillingEntityChange(Number(e.target.value))
								}
								MenuProps={{
									PaperProps: {
										style: getDropdownStylesWithScroll(
											billingEntities.length,
											maximumBillingEntitiesItems
										),
									},
								}}>
								<MenuItem disabled value={-1}>
									Please select a billing entity
								</MenuItem>

								{billingEntities.map((item, index) => {
									return (
										<MenuItem key={index} value={item.PartnerBillingEntityID}>
											{item.PartnerBillingEntityName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>

						{/* Order Type */}
						<Box className='order-form-input'>
							<Typography component='p'>
								Order Type:{' '}
								<Typography component='span' className='required'>
									*
								</Typography>
							</Typography>
							<StyledSelect
								fullWidth
								error={orderTypeError}
								value={orderType}
								type='text'
								onChange={handleOrderTypeChange}>
								<MenuItem disabled value={-1}>
									Please select an order type
								</MenuItem>
								{orderTypeList.map((item, index) => {
									return (
										<MenuItem key={index} value={item.OrderTypeID}>
											{item.OrderTypeName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>

						{/* Previous Orders Dropdown */}
						{showPreviousOrdersField &&
							customerExistingOrdersList.length > 0 && (
								<>
									<Box className='order-form-input'>
										<Typography component='p'>
											Previous Order:
											<Typography component='span' className='required'>
												*
											</Typography>{' '}
										</Typography>
										<StyledSelect
											fullWidth
											error={previousOrderIDError}
											value={previousOrderIDSelected}
											type='text'
											onChange={handleExistingOrderChosen}>
											<MenuItem disabled value={-1}>
												Please select an order ID
											</MenuItem>
											{customerExistingOrdersList.map((item, index) => {
												return (
													<MenuItem key={index} value={item.OrderID}>
														{item.PartnerOrderID}
													</MenuItem>
												)
											})}
										</StyledSelect>
									</Box>
								</>
							)}

						{/* Co-Term  */}
						<Box className='order-form-input'>
							<Typography component='p'>Co-Term: </Typography>
							<Box>
								<Checkbox
									checked={isCoTerm}
									onChange={handleCoTermCheckboxChange}
								/>
							</Box>
						</Box>

						{/* Helper Text */}
						{!isEmptyOrWhitespace(helperText.current) && (
							<Box className='order-form-helper-text'>
								<Typography component='p'>{helperText.current}</Typography>
							</Box>
						)}

						<Divider />

						<Box className='order-form-section-headers'>
							<Typography variant='h6'> SKU Details: </Typography>
							<InfoAlert severity='info'>
								Select the desired SKU from the dropdown, allocate the quantity
								and term, press 'Add SKU' for additional SKUs, and delete any
								SKU while ensuring at least one remains.
							</InfoAlert>
						</Box>

						{/* based on the order type flag thats true, show the revelant component */}
						{showNewOrderTypeComponent && (
							<>
								{Array.from({ length: numberOfSkus }, (_, index) => (
									<NewOrderType
										skuDetailsArray={skuDetailsArray}
										skuDropDownList={skuDropDownList}
										disabledSkus={disabledSkus}
										handleSkuChange={handleSkuChange}
										index={index}
										skuDetailsErrorArray={skuDetailsErrorArray}
										handleQuantityChange={handleQuantityChange}
										handleSkuTermChange={handleSkuTermChange}
										deleteConfirmation={deleteConfirmation}
										numberOfSkus={numberOfSkus}
										skuTermYearsList={skuTermYearsList}
										isCoTerm={isCoTerm}
										autoRenewPeriodList={autoRenewPeriodList}
										handleAutoRenewTermChange={handleAutoRenewTermChange}
										disableDeleteButtonForNewOrRenewalOrderType={
											disableDeleteButtonForNewOrRenewalOrderType
										}
									/>
								))}
							</>
						)}

						{showModifyOrderTypeComponent && (
							<>
								{Array.from({ length: numberOfSkus }, (_, index) => (
									<ModifyOrderType
										skuDetailsArray={skuDetailsArray}
										skuDropDownList={skusforPreviousOrderIDList}
										disabledSkus={disabledSkus}
										handleSkuChange={handleSkuChange}
										index={index}
										skuDetailsErrorArray={skuDetailsErrorArray}
										handleQuantityChange={handleQuantityChange}
										deleteConfirmation={deleteConfirmation}
										numberOfSkus={numberOfSkus}
										autoRenewPeriodList={autoRenewPeriodList}
										handleAutoRenewTermChange={handleAutoRenewTermChange}
										disableDeleteButtonForModifyOrTrueUpOrderType={
											disableDeleteButtonForModifyOrTrueUpOrderType
										}
									/>
								))}
							</>
						)}

						{showTrueUpOrderTypeComponent && (
							<>
								{Array.from({ length: numberOfSkus }, (_, index) => (
									<TrueUpOrderType
										skuDetailsArray={skuDetailsArray}
										skuDropDownList={skusforPreviousOrderIDList}
										disabledSkus={disabledSkus}
										handleSkuChange={handleSkuChange}
										index={index}
										skuDetailsErrorArray={skuDetailsErrorArray}
										handleQuantityChange={handleQuantityChange}
										deleteConfirmation={deleteConfirmation}
										numberOfSkus={numberOfSkus}
										autoRenewPeriodList={autoRenewPeriodList}
										handleAutoRenewTermChange={handleAutoRenewTermChange}
										disableDeleteButtonForModifyOrTrueUpOrderType={
											disableDeleteButtonForModifyOrTrueUpOrderType
										}
									/>
								))}
							</>
						)}

						{showRenewalOrderTypeComponent && (
							<>
								{Array.from({ length: numberOfSkus }, (_, index) => (
									<RenewalOrderType
										skuDetailsArray={skuDetailsArray}
										skuDropDownList={skusforPreviousOrderIDList}
										disabledSkus={disabledSkus}
										handleSkuChange={handleSkuChange}
										index={index}
										skuDetailsErrorArray={skuDetailsErrorArray}
										handleQuantityChange={handleQuantityChange}
										handleSkuTermChange={handleSkuTermChange}
										deleteConfirmation={deleteConfirmation}
										numberOfSkus={numberOfSkus}
										skuTermYearsList={skuTermYearsList}
										isCoTerm={isCoTerm}
										autoRenewPeriodList={autoRenewPeriodList}
										handleAutoRenewTermChange={handleAutoRenewTermChange}
										disableDeleteButtonForNewOrRenewalOrderType={
											disableDeleteButtonForNewOrRenewalOrderType
										}
									/>
								))}
							</>
						)}

						{addSku || numberOfSkus > 0 ? deleteDialog() : null}

						<Box className='order-form-button'>
							<Tooltip title={addSKUToolTip}>
								<span>
									<StyledLoadingButton
										disabled={disableAddSKUButton}
										onClick={displaySkuDetails}>
										Add Sku +
									</StyledLoadingButton>
								</span>
							</Tooltip>
						</Box>
					</Box>

					<Divider />

					<Box className='order-form-input'>
						<Typography component='p'>Add Order Notes</Typography>
						<Box>
							<Checkbox
								checked={hasOrderNotes}
								onChange={handleOrderNotesCheckboxChange}
							/>
						</Box>
					</Box>

					{/* Order Notes */}
					{hasOrderNotes && (
						<Box
							sx={{
								display: 'flex',
								rowGap: '5px',
								flexDirection: 'column',
							}}>
							<StyledTextField
								maxRows={10}
								minRows={10}
								multiline={true}
								value={orderNotes}
								placeholder='Add your notes here...'
								onChange={(e) => {
									e.target.value.length <= OrderNotesMaximumCharactersAllowed &&
										setOrderNotes(e.target.value)
								}}
							/>
							<span
								style={{
									fontSize: '12px',
									color: 'grey',
									textAlign: 'right',
									marginTop: 3,
								}}>
								({orderNotes.length} / {OrderNotesMaximumCharactersAllowed})
							</span>
						</Box>
					)}

					<Divider />

					<Box className='order-form-button'>
						<Tooltip
							title={
								disableReviewButton
									? 'Please complete the Order and SKU details before reviewing the order.'
									: 'Review Order'
							}>
							<span>
								<StyledLoadingButton
									disabled={disableReviewButton}
									loading={loadingButton}
									onClick={handleOpenModal}>
									Review Order
								</StyledLoadingButton>
							</span>
						</Tooltip>
					</Box>

					{/* footer text */}
					<InfoAlert
						severity='info'
						style={{ fontSize: '12px', justifyContent: 'center' }}>
						Click 'Review Order' and then you will be redirected to view the
						order summary.
					</InfoAlert>

					{/*Order Summary Screen*/}
					{!fetchSummaryData && apiCallSuccess && (
						<Modal open={openModal} onClose={handleCloseModal}>
							<Box sx={modalStyle}>
								<AddOrderSummary
									partnerID={partnerID}
									orderSummaryResponse={orderSummary}
									customerPartnerReference={customerPartnerReference}
									isCoTerm={isCoTerm}
									hasOrderNotes={hasOrderNotes}
									orderNotes={orderNotes}
									skusforPreviousOrderIDList={skusforPreviousOrderIDList}
									previousSKUTerm={previousSKUTerm.current}
									previousSKUActivationDate={previousSKUActivationDate.current}
									handleCloseOrderFormModal={handleCloseOrderFormModal}
								/>
								<></>
							</Box>
						</Modal>
					)}
				</Box>
			</Box>
		</>
	)
}

export default AddOrder
