export enum ReturnTypes {
	ObjectOrList = 1,
	String = 2,
}

// Menu Category - Used for the menu items to display the different categorized items
export enum MenuCategories {
	Main = 1,
	Other = 2,
	Hidden = 3,
}

// Text Colours - Black or white for customization
export enum TextColourOptions {
	Black = 1,
	White = 2,
}

// Roles - All the different roles you can have on the site
export enum Roles {
	ITEngineer = 1,
	Sales = 2,
	PMO = 3,
	InternalAdmin = 4,
	ComplianceReporting = 5,
	ITSupport = 6,
	Porting = 7,
	PartnerAdmin = 8,
	SelfService = 9,
	CustomerAdmin = 10,
	ReportingBilling = 11,
}

// Box Status - Used for styling to display loading box, success or error box
export enum BoxStatuses {
	Loading = 1,
	Complete = 2,
	Failure = 3,
}

// Order Status - Used for prechecks
export enum PrechecksOrderStatuses {
	NoTeamsOrderFound = 0,
	NotReadyForSDA = 1,
	ReadyForSDA = 2,
	SDACompleted = 3,
}

// DDI Status - Used for prechecks
export enum PrechecksDDIStatus {
	NoDDIsFound = 0,
	UserHasInvalidDDIRegions = 1,
	UserHasDDIsInValidRegions = 2,
}

// SM Status - Used for Prechecks
export enum PrecheckSMStatus {
	NoSMStatus = 0,
	FailedSMStatus = 1,
	SuccessSMStatus = 2,
}

// Status - Used to prechecks display
export enum PrecheckStatuses {
	'No Teams Service Configuration found.' = 0,
	'Valid Teams Service Configuration found.' = 1,
	'No Voice Catch Order Found.' = 2,
	'Voice Catch Order is not ready for Teams Direct Routing.' = 3,
	'Voice Catch Order is ready for Teams Direct Routing.' = 4,
	'Teams Direct Routing Process has been completed for the found VC Order.' = 5,
	'No DDIs have been found.' = 6,
	'DDIs and supported Trunks do not belong in the same region.' = 7,
	'DDIs and supported Trunks are valid.' = 8,
	'No Service Manager Configuration found.' = 9,
	'There is an error with the Teams Direct Configuration. Please click the button to reconfigure.' = 10,
	'Teams Direct Routing and Service Manager has been configured successfully.' = 20,
}

// Precheck Steps - Used for the loading box to change text
export enum PrecheckSteps {
	'Checking for an existing Teams Service Configuration.' = 0,
	'Checking for a valid Voice Catch Order.' = 1,
	'Checking and validation your DDIs, Trunks and the relevant regions.' = 2,
	'Checking for an existing Service Manager Configuration.' = 3,
}

// Regions - Used for region mapping, prechecks, etc.
export enum RegionOptions {
	AMER = 1,
	APAC = 2,
	EMEA = 3,
}

// Token Type - Used for graph calls
export enum TokenType {
	GraphToken = 1,
	TeamsToken = 2,
}

// Microsoft Graph Calls - Teams User MGT and SDA
export enum Endpoints {
	ME_INFO = 1,
	DIRECTORY_ROLES = 2,
	GET_LICENSES = 3,
	GET_ALL_USER_LICENSES = 4,
	GET_LINE_URI_AND_ROUTING_PLANS = 5,
}

// Partners
export enum Partners {
	No_Partner = 1,
	Lumen = 2,
	Advania = 3,
	'Software One' = 4,
	'O2 Business' = 5,
	Sipcom = 6,
	'Cloud 9' = 7,
	IPC = 8,
	DemoPartner = 9,
	Mirus = 10,
	'Digital Voice' = 11,
}

// Order SKU Status
export enum OrderSKUStatuses {
	'In Progress' = 1,
	Active = 2,
}

// Order statuses
export enum OrderStatuses {
	AwaitingPartnerApproval = 'AW-PART-APP',
	PartnerApproved = 'PART-APP',
	AwaitingSalesApproval = 'AW-SA-APP',
	SalesApproved = 'SA-APP',
	AwaitingFinanceApproval = 'AW-FIN-APP',
	FinanceApproved = 'FIN-APP',
	SystemApproved = 'SYS-APP',
	InProgress = 'INPROG',
	Complete = 'COMP',
	Rejected = 'REJEC',
	Active = 'ACTIV',
}

// Permissions
export enum Permissions {
	ViewCustomer = 1,
	AddCustomer = 2,
	UpdateCustomer = 3,
	DeleteCustomer = 4,
	AddMSTeamsUser = 5,
	UpdateMSTeamsUser = 6,
	ViewSharedCarriers = 7,
	AddSharedCarriers = 8,
	UpdateSharedCarriers = 9,
	DeleteSharedCarriers = 10,
	ViewMCCA = 11,
	AddMCCA = 12,
	UpdateMCCA = 13,
	DeleteMCCA = 14,
	ViewUCMC = 15,
	AddUCMC = 16,
	UpdateUCMC = 17,
	DeleteUCMC = 18,
	ViewZoom = 19,
	AddZoom = 20,
	DeleteZoom = 21,
	ViewBYOR = 22,
	AddBYOR = 23,
	UpdateBYOR = 24,
	DeleteBYOR = 25,
	ViewDDI = 26,
	AddDDI = 27,
	UpdateDDI = 28,
	ViewUsers = 29,
	AddUsers = 30,
	UpdateUsers = 31,
	DeleteUsers = 32,
	ViewOrders = 33,
	AddOrders = 34,
	UpdateOrders = 35,
	ViewReports = 36,
	ExportReports = 37,
	AddTeamsDirectRoutingConfig = 38,
	ViewTeamsDirectRoutingConfig = 39,
	ViewMSTeamsUser = 40,
	DeleteMSTeamsUser = 41,
	ViewTrunkGroups = 42,
	AddTrunkGroups = 43,
	UpdateTrunkGroups = 44,
	DeleteTrunkGroups = 45,
	UpdateZoom = 46,
	MSTeamsExtentionUpload = 47,
	ManageTeamsUsers = 48,
	ProvisionTeamsDirectRouting = 49,
	ViewDashboard = 50,
	ViewPriceBooks = 51,
	AddPriceBooks = 52,
	UpdatePriceBooks = 53,
	ViewAddress = 54,
	AddAddress = 55,
	UpdateAddress = 56,
	ViewSettings = 57,
	ViewAppearanceSettings = 58,
	ViewPortalUserSettings = 59,
	ViewCommunicationSettings = 60,
}

export enum PricingTypes {
	Standard = 1,
	'Standard No Range' = 2,
	'Standard No Term' = 3,
	'Standard No Range No Term' = 4,
	Accumulator = 5,
	Tier = 6,
}

export enum RadioValues {
	PartnerAdmin = 'Partner Admin',
	CustomerAdmin = 'Customer Admin',
}

export enum UserManagementType {
	Members = 1,
	User = 2,
	DeleteUser = 3,
	PendingInvites = 4,
}

// Customer Statuses - Used for customer management display
export enum CustomerStatuses {
	Active = 1,
	'Not Active' = 2,
}

export enum OCLeadStatus {
	ConsentSubmitted = 1,
	ConsentAcknowledged = 2,
	CustomerContacted = 3,
	AgreementSigned = 4,
	ConsentDeclined = 5,
	ContractTerminated = 6,
	ConsentNotSubmitted = 100,
}

export enum LeadTypes {
	OC = 1,
	DirectRouting = 2,
}

export enum LeadStatus {
	Live = 1,
	Sold = 2,
	Dead = 3,
}

export enum LeadModals {
	UpdateLead = 0,
	WarmLead = 1,
}

export enum LeadWeightEnum {
	Pipeline = 1,
	'Best Case' = 2,
	Commit = 3,
	'Closed Won' = 4,
	'Closed Lost' = 5,
}

export enum ContactDetailTypes {
	Billing = 1,
	Engineering = 2,
	Sales = 3,
	Admin = 4,
	Finance = 5,
}

// Generic Form Element Types
export enum GenericFormElementTypes {
	TextField = 1,
	NumberField = 2,
	SelectField = 3,
	CheckBoxField = 4,
	Section = 5, // Sub-heading
	Divider = 6,
}

export enum Countries {
	UK = 232,
}

export enum AddressTypes {
	Billing = 1,
	Corporate = 2,
	Residential = 3,
	Emergency = 4,
	Government = 5,
	Microsoft = 6,
}

export enum OrderTypes {
	New = 1,
	Modify = 2,
	Renewal = 3,
	TrueUp = 4,
	// Disconnect = 5, not in use yet
}

// DDI Enums
export enum DDITypes {
	Port = 1,
	Test = 2,
	New = 3,
	Resource = 4,
	User = 5,
	PartnerManaged = 6,
}

export enum TeamsDRServiceValueDisplayOptions {
	PartnerServiceID = 1,
	DomainName = 2,
	ServiceID = 3,
}

export enum SDAStatus {
	NoTeamsOrderSKUFound = 0,
	NotReadyForSDA = 1,
	ReadyForSDA = 2,
	SDACompleted = 3,
}

export enum ProvisioningSteps {
	ProvisioningStarted = 1,
	CreatingDomains = 2,
	VerifyingDomains = 3,
	ConfiguringServiceAccounts = 4,
	ConfiguringTeamsDRVoice = 5,
	TeamsDRVoiceConfigurationComplete = 6,
	StartingServiceManagerConfiguration = 7,
	AddingTeamsAdminRoleToSMApp = 8,
	ServiceManagerConfigurationComplete = 9,
	TeamsDRConfigurationError = 101,
	ServiceManagerError = 102,
}

export enum SDASteps {
	GlobalAdminAndHaloUsersLicenseCheck = 1,
	LicenseSelection = 2,
	CreatingAndVerifyingDomains = 3,
	TeamsDRVoiceConfiguration = 4,
	ServiceManagerConfiguration = 5,
	SDAConfigurationComplete = 6,
}

export enum ProductActionStatus {
	COMP,
	FAIL,
	'IN-PROG',
	'NOT-START',
}

export enum ServiceManagementStatusCheck {
	NoServiceManagementConfigFound = 0,
	ServiceManagementFailure = 1,
	ServiceManagementSuccess = 2,
}

export enum UserAdminPermissionsEnums {
	GlobalAdmin = 1,
	TeamsAdmin = 2,
	TeamsCommunicationsAdmin = 3,
}

export enum SMInstallOnlyLocation {
	SMCard = 1,
	TeamsDRCard = 2,
}

export enum DDIStatuses {
	Received = 1,
	Allocated = 2,
	PendingEmergencyAddressData = 3,
	Validation = 4,
	FOC = 5,
	Inactive = 6,
	Active = 7,
	Test = 8,
	Exported = 9,
	Terminated = 10,
	ReadyForImplementation = 11,
}

// TUM Build Statuses (Used for loading the Teams User MGT page)
export enum TUMBuildStatuses {
	NotYetStarted = 0,
	Loading = 1,
	Success = 2,
	Failure = 3,
}

export enum TUMPagination {
	Next = 1,
	Previous = 2,
}

export enum Currencies {
	GBP = 1,
	USD = 2,
	EUR = 3,
	No_Currency = 4,
}

// Phone Number type - Used for Teams User MGT
export enum TUMPhoneNumberTypes {
	'TDR' = 1,
	'OC' = 12,
}

// Config type - Used for Teams User MGT
export enum TUMConfigurationTypes {
	'Add License' = 1,
	'Remove License' = 2,
	'Add TN' = 3,
	'Remove TN' = 4,
}

// Teams User MGT Filter select
export enum TUMUserFilterOptions {
	'Username (UPN)' = 1,
	'Name' = 2,
}

// Teams User MGT - Overview Display Options
export enum TUMOverviewDisplayOptions {
	BaseLicenseDisplay = 1,
	AddonLicenseDisplay = 2,
	DDIRangeDisplay = 3,
}

export enum ServiceTypes {
	MSTeamsDirectRouting = 1,
	Zoom = 2,
	UCMC = 3,
	MCCA = 4,
	BYOR = 5,
	BYOC = 6,
	BYOT = 7,
	BYPT = 8,
	DSIP = 9,
	SharedCarrier = 10,
	BYPC = 11,
	MSTeamsOperatorConnect = 12,
}

// Teams User MGT - Update statuses
export enum TUMUpdateStatuses {
	Pending = 0,
	Success = 1,
	Failure = 2,
}

export enum CustomerPartnerTypes {
	No_Partner = 1,
	Lumen = 2,
	Advania = 3,
	'Software One' = 4,
	'O2 Business' = 5,
	Sipcom = 6,
	'Cloud 9' = 7,
	IPC = 8,
	Colt = 9,
}

export enum CarrierTypes {
	No_Carrier = 1,
}

export enum TrueFalseAny {
	False = 0,
	True = 1,
	Any = 2,
}

export enum FilteredOrderStatus {
	NoRecords = 0,
	Success = 1,
	Failure = 2,
}

export enum OrderOverviewModals {
	AssignTNAddresses = 1,
}

export enum OrderSKUAutoRenewTerm {
	'No Term' = 1,
	'1 Month' = 2,
	'12 Months' = 3,
}

// TN and TN Range Filter Mappings
export enum TNFilterKeyMappings {
	// TN
	DDI = 'Number',
	ServiceInID = 'Ingress Service',
	ServiceOutID = 'Egress Service',
	ServiceTypeInID = 'Ingress Service Type',
	ServiceTypeOutID = 'Egress Service Type',
	DDIStatusID = 'Number Status',
	// TN Range
	RangeStart = 'Number Start',
	RangeEnd = 'Number End',
	// Common in both
	CustomerName = 'Customer Name',
	AddressLine1 = 'Address Line 1',
}

//TN Ranges Filters
export enum TNRangesFilterKeyMappings {
	RangeStart = 'Number Start',
	RangeEnd = 'Number End',
	CustomerName = 'Customer Name',
}

// Order Filter Mappings
export enum OrderFilterKeyMappings {
	OrderID = 'Order ID',
	PartnerOrderID = 'Order Reference',
	CustomerPartnerReference = 'Customer Reference',
	CustomerName = 'Customer Name',
	OrderStatusID = 'Order Status',
	OrderTypeID = 'Order Type',
	PreviousOrderID = 'Previous Order ID',
	ExternalOrderID = 'Generated Order ID',
}

// Current usage in role emails mgt. Used to track if something in the form has changed.
export enum FormChangeStatus {
	NoChange = 0,
	NewValue = 1,
	UpdatedValue = 2,
}

export enum PartnerBillingEntitiesEnum {
	'Level 3 Communications LLC' = 1,
	'Lumen Technologies UK Limited' = 2,
	'Software ONE UK Ltd' = 3,
	'Software ONE UK Ltd US' = 4,
	'Software Pipeline Ireland Ltd' = 5,
	'Software Pipeline Ireland Ltd US' = 6,
	'Advania UK Ltd' = 7,
	'Advania Corporation' = 8,
}
export enum SipcomBillingEntitiesEnum {
	'Sipcom US' = 1,
	'Sipcom UK' = 2,
}
